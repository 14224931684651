// @flow

import request from 'services/request';
import requestFAPI from 'services/requestFAPI';
import adminRequest from 'services/adminRequest';
import type { Coordinates } from 'types';
import { MAPBOX_GL_TOKEN } from 'env';

/**
 * Request user vehicles.
 *
 * @param {string} token
 * @param {string} locale
 * @param {string} sort
 * @param {string} order
 */
export function retrieveVehicles(
    token: string,
    locale: string,
    sort: string,
    order: string,
    moduleTypes: string,
): Promise<> {
    let url = `${locale}/equipment`;
    url = sort && order ? `${url}?sort=${sort}&order=${order}&moduletypes=${moduleTypes}` : url;
    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveVehicle(token: string, locale: string, id: number): Promise<> {
    const url = `${locale}/equipment/${id}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveAdminVehicle(token: string, locale: string, id: number): Promise<> {
    const url = `vehicles/${id}`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {number} id
 */
export function retrieveVehicleDetail(token: string, id: number): Promise<> {
    const url = `vehicles/${id}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 * Request user vehicles.
 *
 * @param {string} token
 * @param {string} locale
 */
export function retrieveEventTypes(token: string, locale: string): Promise<> {
    return request(
        `${locale}/eventtypes`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveEquipmentHistory(
    equipmentId: number,
    token: string,
    locale: string,
    periodDateFrom: string = '',
    periodDateTo: string = '',
    periodTimeFrom: string = '',
    periodTimeTo: string = ''
): Promise<> {
    let periodFrom = '';
    if (periodDateFrom && periodTimeFrom) {
        periodFrom = `${periodDateFrom} ${periodTimeFrom}`;
    }

    let periodTo = '';
    if (periodDateTo && periodTimeTo) {
        periodTo = `${periodDateTo} ${periodTimeTo}`;
    }

    return request(
        `${locale}/equipment/${equipmentId}/realtimes?from=${periodFrom}&to=${periodTo}&order=asc`,
        { method: 'GET' },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} serialID
 */
export function retrieveFleetEquipmentById(
    token: string,
    serialID: number,
    startDate: string,
    endDate: string,
    pageNumber: number
): Promise<> {
    const url = `Fleet/Equipment/SERIAL/${serialID}/Locations/${startDate}/${endDate}/${pageNumber}`;
    return requestFAPI(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveVehicleHistoryDetail(
    token: string,
    id: number,
    startDate: string,
    endDate: string,
    pageNumber: number
): Promise<> {
    const url = `vehicles/${id}/events/${startDate}/${endDate}/${pageNumber}`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function mapboxDirections(coordinates: Coordinates, isOverview) {
    const profile = 'driving';
    const coordinatesList = coordinates.join(';');

    const url = isOverview
        ? `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinatesList}?geometries=geojson&steps=false&overview=full&access_token=${MAPBOX_GL_TOKEN}`
        : `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinatesList}?geometries=geojson&steps=false&access_token=${MAPBOX_GL_TOKEN}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        '',
        false
    );
}

/**
 *
 * @param {*} vehicleGeoDataChunk
 */
export function mapboxMapMatching(vehicleGeoDataChunk) {
    const profile = 'driving';
    const tidy = 'false';

    // If no coordinates, don't try matching them
    if (typeof vehicleGeoDataChunk === 'undefined' || vehicleGeoDataChunk.length === 0) {
        return [];
    }

    const coordinatesArr = [];
    const timestampsArr = [];
    const radiusesArr = [];

    vehicleGeoDataChunk.forEach((chunk) => {
        coordinatesArr.push(chunk.coordinates);
        // timestamps need to be in epoch time = 1576160217 and NOT 1576160217000
        timestampsArr.push(
            chunk.timestamp.toString().length > 10 ? chunk.timestamp / 1000 : chunk.timestamp
        );

        // Adjust radius based on confidence
        let radius = 5;
        if (chunk.confidence === 0) {
            radius = 50;
        } else if (chunk.confidence === 1) {
            radius = 40;
        } else if (chunk.confidence === 2) {
            radius = 30;
        } else if (chunk.confidence === 3) {
            radius = 20;
        } else if (chunk.confidence === 4) {
            radius = 10;
        } else if (chunk.confidence === 5) {
            radius = 5;
        }
        radiusesArr.push(radius);
    });

    const coordinatesList = coordinatesArr.join(';');
    const timestampsList = timestampsArr.join(';');
    const radiusesList = radiusesArr.join(';');

    const url = `https://api.mapbox.com/matching/v5/mapbox/${profile}/${coordinatesList}?radiuses=${radiusesList}&geometries=geojson&overview=full&tidy=${tidy}&access_token=${MAPBOX_GL_TOKEN}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        '',
        false
    );
}

export function mapboxGeoToAddress(latitude, longitude) {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_GL_TOKEN}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        '',
        false
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveExternalVehicle(
    token: string,
    locale: string,
    id: number,
    source: string
): Promise<> {
    const url = `${locale}/equipment/${id}?source=${source}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}


