import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import { ChevronUp, ChevronDown } from '../../components/Icons';
import { Switch } from 'antd';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import './styles.scss';
import { Select, MenuItem, OutlinedInput, FormControl } from '@mui/material';

// Selectors
import { selectConfigRecords } from 'services/Config/selectors';

//Thunks
import {
    isImperial,
    i18nVolumeUnit,
    i18nVolumeLitersUnit,
    i18nDistanceUnit,
    i18nMilesToKm,
} from 'services/Units/thunks';
import { setEcoCopy } from 'services/Config/thunks';

const CustomCheckbox = styled(Checkbox)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #4f9c20;
        border-color: #4f9c20;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #ffffff;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #4f9c20;
    }
`;

function EconomieConfigComponent(props) {
    const [detailed, setDetailed] = useState(true);
    const [selectedTimeStart, setSelectedTimeStart] = useState('07:00');
    const [selectedTimeEnd, setSelectedTimeEnd] = useState('07:00');
    const [fuelType, setFuelType] = useState('gasoline');
    const [checkRalProlong, setCheckRalProlong] = useState(true);
    const [checkEngineCutoff, setCheckEngineCutoff] = useState(true);
    const [litersPerHour, setLitersPerHour] = useState(0);
    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            const hourString = hour < 10 ? `0${hour}` : `${hour}`;
            options.push(`${hourString}:00`);
        }
        return options;
    };
    const handlefuelTypeChange = (event) => {
        setFuelType(event.target.value);
    };
    const handleTimeChangeStart = (event) => {
        setSelectedTimeStart(event.target.value);
    };
    const handleTimeChangeEnd = (event) => {
        setSelectedTimeEnd(event.target.value);
    };
    useEffect(
        () => {
            if (props.step === 1) {
                setDetailed(false);
            }
        },
        [props.step]
    );
    useEffect(
        () => {
            if (props.configRecords.size === 0) return;
            const additionalData = props.configRecords.get('additionalData');
            const engineCutoff = props.configRecords.get('engineCutoff');
            setCheckRalProlong(engineCutoff.get('activeIdleEvent'));
            setFuelType(additionalData.get('fuel_type') || 'gasoline');
            setCheckEngineCutoff(engineCutoff.get('cutoffCutEngine'));
            setLitersPerHour(
                props.i18nVolumeUnit(additionalData.get('liters_per_hour')) ||
                    (props.isImperial() ? 1 : 4)
            );
        },
        [props.configRecords]
    );
    const handleEcoChange = (e) => {
        if (e.target.checked) {
            props.setEcoCopy(true);
        } else {
            props.setEcoCopy(false);
        }
    };
    return (
        <Grid
            container
            sx={{
                height: 'fit-content',
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '20px',
                borderRadius: '7px',
            }}
        >
            <Grid xs={8}>
                <CustomCheckbox onChange={(e) => handleEcoChange(e)} />
                <span className={'economieHeader'}>Economie d'essence</span>
            </Grid>
            <Grid xs={4} sx={{ display: 'flex', justifyContent: 'end', padding: '2px 10px 0 0' }}>
                {detailed ? (
                    <div onClick={() => setDetailed(false)}>
                        <ChevronUp />
                    </div>
                ) : (
                    <div onClick={() => setDetailed(true)}>
                        <ChevronDown />
                    </div>
                )}
            </Grid>
            <Grid xs={12}>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#B6C2E2' }} />
            </Grid>
            {detailed ? (
                <Grid container>
                    <Grid item xs={6} sx={{ padding: '0 10px' }}>
                        <span>Type de Carburant</span>
                        <FormControl fullWidth variant="outlined" sx={{ m: 0 }}>
                            <Select
                                sx={{
                                    width: '70%',
                                    height: '40%',
                                }}
                                value={fuelType}
                                onChange={handlefuelTypeChange}
                            >
                                <MenuItem
                                    key={1}
                                    value={'gasoline'}
                                    sx={{
                                        fontSize: '12px',
                                        fontColor: '#64748B',
                                        margin: '0',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontColor: '#64748B',
                                        }}
                                    >
                                        Essence
                                    </span>
                                </MenuItem>
                                <MenuItem
                                    key={2}
                                    value={'diesel'}
                                    sx={{
                                        fontSize: '12px',
                                        fontColor: '#64748B',
                                        margin: '0',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontColor: '#64748B',
                                        }}
                                    >
                                        Diesel
                                    </span>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sx={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}
                            >
                                <Switch checked={checkRalProlong} />

                                <span style={{ marginLeft: '10px' }}>Ralenti prolongé</span>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}
                            >
                                <Switch checked={checkEngineCutoff} />

                                <span style={{ marginLeft: '10px' }}>Coupe moteur</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{ padding: '0 10px', display: 'flex', flexDirection: 'column' }}
                    >
                        <span>Litres par heure au ralenti</span>
                        <OutlinedInput
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderColor: '#CCCCCC',
                                borderRadius: '8px',
                                width: '70%',
                                height: '40%',
                                input: {
                                    padding: '10px',
                                },
                            }}
                            id="outlined-fuel-quantity"
                            aria-describedby="outlined-fuel-quantity"
                            value={litersPerHour}
                            // onChange={handleConditionNameChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid xs={12}>
                                <CustomCheckbox>Horraire</CustomCheckbox>
                            </Grid>
                            <Grid xs={6}>
                                <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                                    <Select
                                        sx={{ width: '75px', height: '25px' }}
                                        value={selectedTimeStart}
                                        onChange={handleTimeChangeStart}
                                    >
                                        {generateTimeOptions().map((time, index) => (
                                            <MenuItem
                                                key={index}
                                                value={time}
                                                sx={{
                                                    fontSize: '10px',
                                                    fontColor: '#64748B',
                                                    margin: '0',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '10px',
                                                        fontColor: '#64748B',
                                                    }}
                                                >
                                                    {time}
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={6}>
                                <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                                    <Select
                                        sx={{ width: '75px', height: '25px' }}
                                        value={selectedTimeEnd}
                                        onChange={handleTimeChangeEnd}
                                    >
                                        {generateTimeOptions().map((time, index) => (
                                            <MenuItem
                                                key={index}
                                                value={time}
                                                sx={{
                                                    fontSize: '10px',
                                                    fontColor: '#64748B',
                                                    margin: '0',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '10px',
                                                        fontColor: '#64748B',
                                                    }}
                                                >
                                                    {time}
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            marginLeft: '15px',
                            marginBottom: '15px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Switch defaultChecked />

                        <span style={{ marginLeft: '10px' }}>Pré-alerte limite de vitesse</span>
                    </Grid>
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );
}
// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({
    configRecords: selectConfigRecords(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            isImperial,
            i18nVolumeUnit,
            i18nVolumeLitersUnit,
            i18nDistanceUnit,
            i18nMilesToKm,
            setEcoCopy,
        },
        dispatch
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EconomieConfigComponent));
