// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { Handle, Track, Tick } from 'components/ReactCompoundSlider';

type Props = {
    disabled: ?boolean,
    mode: number,
    step: number,
    onChange: ?() => void,
    onUpdate: ?() => void,
    values: Array,
    domain: Array,
};

const sliderStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
};

const railStyle: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: 10,
    borderRadius: 7,
    cursor: 'pointer',
    backgroundColor: 'rgb(155,155,155)',
};

class CustomSlider extends React.PureComponent<Props> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Slider
                mode={this.props.mode}
                step={this.props.step}
                domain={this.props.domain}
                rootStyle={sliderStyle}
                onChange={this.props.onChange}
                onUpdate={this.props.onUpdate}
                values={this.props.values}
                disabled={this.props.disabled}
            >
                <Rail>{({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}</Rail>
                <Handles>
                    {({ handles, getHandleProps }) => (
                        <div className="slider-handles">
                            {handles.map((handle) => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={this.props.domain}
                                    getHandleProps={getHandleProps}
                                    fontSize={typeof handle.value === 'number' && !Number.isInteger(handle.value)
                                                ? 10
                                                : (handle.value.toFixed().toString().length) < 4 ? 12 : 10}
                                    paddingTop={typeof handle.value === 'number' && !Number.isInteger(handle.value)
                                                ? 8
                                                : (handle.value.toFixed().toString().length) < 4 ? 5 : 8}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={getTrackProps}
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
                <Ticks count={this.props.ticks ? this.props.ticks : 10}>
                    {({ ticks }) => (
                        <div className="slider-ticks">
                            {ticks.map((tick) => (
                                <Tick key={tick.id} tick={tick} count={ticks.length} />
                            ))}
                        </div>
                    )}
                </Ticks>
            </Slider>
        );
    }
}

export default injectIntl(CustomSlider);
