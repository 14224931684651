import * as React from 'react';
import colors from 'styles/colors';
import { SliderItem, GetHandleProps, GetTrackProps } from 'react-compound-slider';

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
interface IHandleProps {
    domain: number[];
    handle: SliderItem;
    getHandleProps: GetHandleProps;
}

export const Handle: React.SFC<IHandleProps> = ({
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
    marginLeftSize,
    fontSize,
    paddingTop,
}) => (
    <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
            left: `${percent}%`,
            position: 'absolute',
            marginLeft: '-14px',
            marginTop: '-10px',
            zIndex: 2,
            width: 30,
            height: 30,
            cursor: 'pointer',
            borderRadius: '60%',
            backgroundColor: colors.green4b,
        }}
        {...getHandleProps(id)}
    >
        <center style={{ color: colors.white, fontWeight: 'bold', fontSize: fontSize, paddingTop: paddingTop }}>
            {typeof value === 'number' && !Number.isInteger(value)
                ? value.toFixed(2)
                : value.toFixed()}
        </center>
    </div>
);

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface ITrackProps {
    source: SliderItem;
    target: SliderItem;
    getTrackProps: GetTrackProps;
}

export const Track: React.SFC<ITrackProps> = ({ source, target, getTrackProps }) => (
    <div
        style={{
            position: 'absolute',
            height: 12,
            zIndex: 1,
            backgroundColor: colors.green82,
            borderRadius: 7,
            cursor: 'pointer',
            left: `${source.percent}%`,
            width: `${target.percent - source.percent}%`,
        }}
        {...getTrackProps()}
    />
);

// *******************************************************
// TICK COMPONENT
// *******************************************************
interface ITickProps {
    key: string;
    tick: SliderItem;
    count: number;
}

export const Tick: React.SFC<ITickProps> = ({ tick, count }) => (
    <div>
        <div
            style={{
                position: 'absolute',
                marginTop: 14,
                width: 1,
                height: 5,
                backgroundColor: 'rgb(200,200,200)',
                left: `${tick.percent}%`,
            }}
        />
        <div
            style={{
                position: 'absolute',
                marginTop: 22,
                fontSize: 10,
                textAlign: 'center',
                marginLeft: `${-(100 / count) / 2}%`,
                width: `${100 / count}%`,
                left: `${tick.percent}%`,
            }}
        >
            {tick.value}
        </div>
    </div>
);
