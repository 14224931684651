import { Checkbox } from 'antd';
import styled from 'styled-components';

const CustomCheckbox = styled(Checkbox)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #4f9c20;
        border-color: #4f9c20;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #ffffff;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #4f9c20;
    }
`;
export default CustomCheckbox;
