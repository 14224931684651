import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { selectVehicles, selectEventTypes } from '../../services/Vehicle/selectors';
import { selectCopyTargetVehicles } from '../../services/Config/selectors';
import ConfigCustomCheckbox from 'components/ConfigCustomCheckbox';
import { Checkbox } from 'antd';
import { getStatus, getAuxStatus, getCustomIcon } from 'helpers';
import './styles.scss';
import VehicleIconDisplay from '../VehicleIconDisplay';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
//Thunks
import { getVehicles } from '../../services/Vehicle/thunks';
import {
    getUnauthorizedMoveConfigByEquipementId,
    setSelectVehicle,
    addTargetVehicle,
    removeTargetVehicle,
} from 'services/Config/thunks';
import { setLineData } from '../../views/MapPage/utils';

function VehiculeListConfig(props) {
    const [vehiculeList, setVehiculeList] = useState([]);
    const checkboxRefs = useRef({});
    const [allVehicles,setAllVehicles] = useState(false);
    useEffect(() => {
        props.getVehicles();
    }, []);
    useEffect(
        () => {
            setVehiculeList(props.vehicles);
        },
        [props.vehicles]
    );
    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        const filteredVehicles = props.vehicles.filter((veh) =>
            veh
                .get('name')
                .toLowerCase()
                .includes(query)
        );
        setVehiculeList(filteredVehicles);
    };
    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setAllVehicles(true);
            Object.keys(checkboxRefs.current).forEach((key) => {
                checkboxRefs.current[key].state.checked = true;
            });
            vehiculeList.forEach((veh) => {
                props.addTargetVehicle(veh.get('id'), veh.get('name'))

        });
        } else {
            setAllVehicles(false);
            Object.keys(checkboxRefs.current).forEach((key) => {
                checkboxRefs.current[key].state.checked = false;
            });
                            props.removeTargetVehicle(veh.get('id'));

        }
    };
    const handleChange = (e, veh) => {
        if (props.step === 0) {
            if (e.target.checked) {
                props.setSelectVehicle(veh.get('id'));
            } else {
                props.setSelectVehicle(0);
            }
            Object.keys(checkboxRefs.current).forEach((key) => {
                checkboxRefs.current[key].state.checked = key === veh.get('id').toString();
            });
        } else {
            if (e.target.checked) {
                if (
                    !props.targetVehicules.toJS().find((item) => item.vehicleId === veh.get('id'))
                ) {
                    props.addTargetVehicle(veh.get('id'), veh.get('name'));
                }
            } else {
                props.removeTargetVehicle(veh.get('id'));
            }
        }
    };
    return (
        <Grid container className="vehicleContainer">
            <Grid xs={12}>
                <div
                    style={{
                        fontSize: '18px',
                        fontFamily: 'Inter',
                        fontWeight: '1000',
                        margin: '10px 0 5px 10px',
                    }}
                >
                    Select Vehicle
                </div>
            </Grid>

            <Grid xs={12}>
                <FormControl sx={{ m: 2, width: '30ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        label="outlined-adornment-search"
                        sx={{ height: '35px' }}
                        onChange={handleSearchChange}
                    />
                </FormControl>
            </Grid>
            {props.step === 1 && (
                <Grid
                    xs={12}
                    className="vehiculeItem"
                    style={{
                        fontSize: '14px',
                        fontFamily: 'inter',
                        paddingBottom: '20px',
                        fontWeight: '900',
                        color: '#101828',
                        paddingLeft: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Checkbox onChange={(e) => handleSelectAllChange(e)} />
                    <span style={{ marginLeft: '10px' }}>Select All</span>
                </Grid>
            )}
            {vehiculeList.size > 0
                ? vehiculeList.map((veh) => {
                      const auxArray = new Array(4).fill(0);

                      if (veh.getIn(['latestRealTime', 'sensorAux'])) {
                          auxArray[0] = 1;
                      }
                      if (veh.getIn(['latestRealTime', 'sensorAux2'])) {
                          auxArray[1] = 1;
                      }
                      if (veh.getIn(['latestRealTime', 'sensorAux3'])) {
                          auxArray[2] = 1;
                      }
                      if (veh.getIn(['latestRealTime', 'sensorAux4'])) {
                          auxArray[3] = 1;
                      }
                      const isKey = veh.getIn(['latestRealTime', 'sensorKey']);
                      const status = veh.get('status')
                          ? getStatus(
                                veh,
                                // veh.get('latestRealTime').get('eventTypeId'),
                                veh.getIn(['status', 'eventTypeId']),
                                props.eventTypes,
                                true,
                                isKey
                            )
                          : null;
                      const isAux =
                          veh.getIn(['latestRealTime', 'sensorAux']) ||
                          veh.getIn(['latestRealTime', 'sensorAux2']) ||
                          veh.getIn(['latestRealTime', 'sensorAux3']) ||
                          veh.getIn(['latestRealTime', 'sensorAux4']);
                      const auxStatus = getAuxStatus(auxArray);
                      return (
                          <Grid
                              xs={12}
                              md="12"
                              className="vehiculeItem"
                              style={{
                                  fontSize: '14px',
                                  fontFamily: 'inter',
                                  border: 'solid 1px #EAECF0',
                                  paddingBottom: '20px',
                                  paddingTop: '20px',
                                  fontWeight: '900',
                                  color: '#101828',
                                  paddingLeft: '20px',
                                  display: 'flex',
                                  flexDirection: 'row',
                              }}
                              onClick={() => {
                                  props.onClickConfig(veh.get('id'));
                                  props.getUnauthorizedMoveConfigByEquipementId(veh.get('id'));
                              }}
                          >
                              <Checkbox
                                  key={veh.get('id')}
                                  ref={(el) => (checkboxRefs.current[veh.get('id')] = el)}
                                  onChange={(e) => handleChange(e, veh)}
                              />
                              {getCustomIcon(veh).id >= 0 || (status && status.icon) ? (
                                  <div
                                      style={{
                                          left: '15px',
                                          margin: 'auto 0',
                                          transition: 'all 250ms ease-in-out',
                                      }}
                                  >
                                      <VehicleIconDisplay
                                          customIcon={getCustomIcon(veh)}
                                          statusIcon={status && status.icon}
                                          status={status}
                                          idVehicle={veh.get('id')}
                                          vehicle={veh}
                                      />
                                      {isAux ? (
                                          <img
                                              src={auxStatus && auxStatus.icon}
                                              width={'28px'}
                                              height={'28px'}
                                              alt={auxStatus.description}
                                              title={auxStatus.description}
                                              style={{ margin: '0', objectFit: 'contain' }}
                                          />
                                      ) : null}
                                  </div>
                              ) : null}
                              <div style={{ marginLeft: '10px' }}>{veh.get('name')}</div>
                          </Grid>
                      );
                  })
                : ''}
        </Grid>
    );
}
const mapStateToProps: Object = createStructuredSelector({
    vehicles: selectVehicles(),
    eventTypes: selectEventTypes(),
    targetVehicules: selectCopyTargetVehicles(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getVehicles,
            getUnauthorizedMoveConfigByEquipementId,
            setSelectVehicle,
            addTargetVehicle,
            removeTargetVehicle,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(VehiculeListConfig))
);
