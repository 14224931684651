// @flow

import React, { useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippy.js/react';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import { TimeGridScheduler, classes } from '@remotelock/react-week-scheduler';
import 'assets/react-week-scheduler_styles.css';
import { SchedulerContext } from '@remotelock/react-week-scheduler';
import OutlineButton from 'components/OutlineButton';
import { Row, Col } from 'react-bootstrap';
import colors from 'styles/colors';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en';
import es from 'date-fns/locale/es';

type Props = {
    rangeStrings: Array,
    locale: String,
    handleChange: *,
};

export const DefaultEventRootComponent = React.memo(
    React.forwardRef(
        ({ isActive, handleDelete, cellIndex, rangeIndex, classes, disabled, ...props }, ref) => {
            return <div ref={ref} aria-disabled={true} {...props} />;
        }
    )
);

const EventRoot = React.forwardRef(({ handleDelete, disabled, ...props }, ref) => {
    return (
        <Tippy
            arrow
            interactive
            isEnabled={!disabled}
            hideOnClick={false}
            className={''}
            content={
                <div>
                    <span>
                        {ref.current == null
                            ? ' '
                            : ref.current.firstChild.firstChild.firstChild != null
                                ? ref.current.firstChild.firstChild.firstChild.innerText
                                : ''}
                    </span>
                    <br />
                    <button
                        disabled={disabled}
                        onClick={handleDelete}
                        className="styles-module_timeline_button"
                    >
                        <i className="far fa-trash-alt" /> &nbsp;{' '}
                        <FormattedMessage
                            id={
                                'components.Modals.Form.Config.DeplacementNoNAutorise.plageHoraire.supp'
                            }
                        />
                    </button>
                </div>
            }
        >
            <DefaultEventRootComponent
                handleDelete={handleDelete}
                disabled={disabled}
                {...props}
                ref={ref}
            />
        </Tippy>
    );
});

/**
 * Funtion : return Array values to parent EquiptementConfigModal
 */
function getChangedItems(item) {
    return item;
}
function removeDuplicates (arr) {
    let uniqueDates = [];
    arr.filter(item => {
        if (item[0] !== item[1]) {
            uniqueDates.push(item)
        }
    });
    return uniqueDates
}
function TimeGridSchedulerComponent(props: Props) {
    const defaultSchedule = removeDuplicates(props.rangeStrings).map((range) =>
        range.map((dateString) => new Date(dateString))
    );
    const [schedule, setSchedule] = useState(defaultSchedule);
    const [visualGridVerticalPrecision] = useState(35);
    const [cellWidth] = useState(2);
    const [cellHeight] = useState(10);
    let locale = en;
    switch (props.locale) {
        case `fr`: locale = fr; break;
        case `en`: locale = en; break;
        case `es`: locale = es; break;
        default: locale = en;
    }

    return (
        <div>
            <Row>
                <Col>
                    <div
                        className="root"
                        style={{
                            height: 460,
                            '--cell-height': `${cellHeight}px`,
                            '--cell-width': `${cellWidth}px`,
                        }}
                    >
                        <Fragment key={`${cellHeight},${cellWidth}`}>
                            <SchedulerContext.Provider value={{ locale }}>
                                <TimeGridScheduler
                                    classes={classes}
                                    style={{ width: '100%', height: '100%' }}
                                    originDate={new Date('2019-04-08')}
                                    schedule={schedule}
                                    onChange={async (e) => {
                                        await setSchedule(e);
                                        await props.handleChange(getChangedItems(e));
                                    }}
                                    verticalPrecision={5}
                                    cellClickPrecision={60}
                                    visualGridVerticalPrecision={visualGridVerticalPrecision}
                                    eventRootComponent={EventRoot}
                                />
                            </SchedulerContext.Provider>
                        </Fragment>
                    </div>
                </Col>
            </Row>
            {schedule.length > 0 ? (
                <Row style={{ paddingTop: '10px' }}>
                    <Col>
                        <OutlineButton
                            outlineColor={colors.green73}
                            backgroundColor={colors.green73}
                            hoverBackgroundColor={colors.white}
                            hoverTextColor={colors.green73}
                            textColor={colors.white}
                            onClick={async () => {
                                await setSchedule([]);
                                await props.handleChange(getChangedItems([]));
                            }}
                        >
                            <FormattedMessage
                                id={'components.Modals.Form.Config.DetecMoteur.Reset'}
                            />
                        </OutlineButton>
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}

export default TimeGridSchedulerComponent;
