import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {
    ApReservationStepIcon,
    ApCheckMarkIcon,
    ApCloseIcon,
    ApStepperSeperator,
    CopyIcon,
} from '../../components/Icons';
import VehiculeListConfig from '../../components/VehiculeListConfig';
import NotificationConfig from '../../components/NotificationConfig';
import EconomieConfigComponent from '../../components/EconomieConfigComponent';
import UnauthorizedMoveConfigBox from '../../components/UnauthorizedMoveConfigBox';
import LowPowerModeSettings from '../../components/LowPowerModeSettings';
import AccelerometreSettings from '../../components/AccelerometreSettings';
import FinishCopyComponent from '../../components/FinishCopyComponent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import './styles.scss';
import {
    getConfigByEquipementId,
    getEmailNotifConfigByEquipementId,
    copyConfiguration,
} from 'services/Config/thunks';
import {
    selectConfigCopyNotif,
    selectConfigCopyEco,
    selectConfigCopyUnauth,
    selectConfigCopyLpm,
    selectConfigCopyAccel,
    selectCopyTargetVehicles,
    selectVehicleToCopy,
} from '../../services/Config/selectors';

function Configuration(props) {
    const getEquipmentConfig = (equipmentId) => {
        props.getConfigByEquipementId(equipmentId);
        props.getEmailNotifConfigByEquipementId(equipmentId);
    };
    const steps = [
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Config',
        }),
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Target',
        }),
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Finish',
        }),
    ];
    const [activeStep, setActiveStep] = useState(0);
    return (
        <Grid container sx={{ mt: 5, height: 'fit-content' }}>
            {
                activeStep > 0 && (
                    <Grid item xs={2} style={{ justifyContent: 'start', display: 'flex' }}>
                        <Button
                            variant="outlined"
                            sx={{
                                height: 'fit-content',
                                textTransform: 'none',
                                backgroundColor: '#4F9C20',
                                color: 'white',
                                borderColor: '#4F9C20',
                                '&:hover': {
                                    backgroundColor: '#3E7C16',
                                    borderColor: '#3E7C16',
                                },
                                margin: '0.5em',
                            }}
                            onClick={() => {
                                setActiveStep(activeStep - 1);
                            }}
                        >
                            <span>Back</span>
                        </Button>
                    </Grid>
                )
            }
            <Grid item xs={8}>
                <Stepper
                    activeStep={activeStep}
                    sx={{ justifyContent: 'center', width: '115%' }}
                    connector={<ApStepperSeperator />}
                >
                    {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel
                                    StepIconComponent={() =>
                                        ApReservationStepIcon(index, activeStep)
                                    }
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item xs={2} mb={5} style={{ justifyContent: 'end', display: 'flex' }}>
                {activeStep === steps.length - 3 ? (
                    <Button
                        variant="outlined"
                        startIcon={<CopyIcon />}
                        sx={{
                            height: 'fit-content',
                            textTransform: 'none',
                            backgroundColor: '#4F9C20',
                            color: 'white',
                            borderColor: '#4F9C20',
                            '&:hover': {
                                backgroundColor: '#3E7C16',
                                borderColor: '#3E7C16',
                            },
                        }}
                        onClick={() => {
                            setActiveStep(1);
                        }}
                    >
                        <span>Copy Configuration</span>
                    </Button>
                ) : activeStep === steps.length - 2 ? (
                    <Button
                        variant="outlined"
                        sx={{
                            height: 'fit-content',
                            textTransform: 'none',
                            backgroundColor: '#4F9C20',
                            color: 'white',
                            borderColor: '#4F9C20',
                            '&:hover': {
                                backgroundColor: '#3E7C16',
                                borderColor: '#3E7C16',
                            },
                        }}
                        onClick={() => {
                            setActiveStep(2);
                        }}
                    >
                        <span>Suivant</span>
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        sx={{
                            height: 'fit-content',
                            textTransform: 'none',
                            backgroundColor: '#4F9C20',
                            color: 'white',
                            borderColor: '#4F9C20',
                            '&:hover': {
                                backgroundColor: '#3E7C16',
                                borderColor: '#3E7C16',
                            },
                        }}
                        onClick={() => {
                            props.copyConfiguration(
                                props.vehicleToCopy,
                                props.copyTargetVehicles.toJS(),
                                props.configCopyEco,
                                props.configCopyNotif,
                                props.configCopyUnauth,
                                props.configCopyLpm,
                                props.configCopyAccel
                            );
                        }}
                    >
                        <span>Finish</span>
                    </Button>
                )}
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            {activeStep === steps.length - 1 ? (
                <Grid container className="finishContainer">
                    <FinishCopyComponent />
                </Grid>
            ) : (
                <Grid container>
                    <Grid item xs={3}>
                        <VehiculeListConfig step={activeStep} onClickConfig={getEquipmentConfig} />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container>
                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <EconomieConfigComponent step={activeStep} />
                            </Grid>

                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <NotificationConfig step={activeStep} />
                            </Grid>

                            <Grid xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <UnauthorizedMoveConfigBox step={activeStep} />
                            </Grid>
                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <Grid container>
                                    <Grid xs={12} style={{ marginBottom: '1em' }}>
                                        <LowPowerModeSettings step={activeStep} />
                                    </Grid>
                                    <Grid xs={12}>
                                        <AccelerometreSettings step={activeStep} />'
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
const mapStateToProps: Object = createStructuredSelector({
    configCopyEco: selectConfigCopyEco(),
    configCopyNotif: selectConfigCopyNotif(),
    configCopyUnauth: selectConfigCopyUnauth(),
    configCopyLpm: selectConfigCopyLpm(),
    configCopyAccel: selectConfigCopyAccel(),
    copyTargetVehicles: selectCopyTargetVehicles(),
    vehicleToCopy: selectVehicleToCopy(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        { getConfigByEquipementId, getEmailNotifConfigByEquipementId, copyConfiguration },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Configuration))
);
