import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { copyConfiguration } from 'services/Config/thunks';
import {
    selectCopyTargetVehicles,
    selectConfigCopyEco,
    selectConfigCopyNotif,
    selectConfigCopyUnauth,
    selectConfigCopyLpm,
    selectConfigCopyAccel,
} from 'services/Config/selectors';
function FinishCopyComponent(props) {
    return (
        <Grid
            container
            sx={{
                height: 'fit-content',
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '20px',
                borderRadius: '7px',
            }}
        >
            <Grid xs={12} className="economieHeader economieHeader_t1">
                <span >Review</span>
            </Grid>
            <Grid xs={12} className="economieHeader ">
            <span >Selected Vehicules</span>
            <Grid container className="economieHeader economieHeader_t2">
              
                {props.targetVehicules.toJS().map((item) => (
                <Grid xs={12}>{item.vehiculeName}</Grid>
            ))}
              </Grid>
            </Grid>
            
            <Grid xs={12} className="economieHeader ">
            <span >Selected Configurations</span>
            <Grid container className="economieHeader economieHeader_t2">
          
                            {props.configCopyEco && <Grid xs={12}>Economie</Grid>}
            {props.configCopyNotif && <Grid xs={12}>Notification</Grid>}
            {props.configCopyUnauth && <Grid xs={12}>Unauthorized</Grid>}
            {props.configCopyLpm && <Grid xs={12}>Lpm</Grid>}
            {props.configCopyAccel && <Grid xs={12}>Accel</Grid>}
            </Grid> 
              </Grid>

        </Grid>
    );
}

// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({
    configCopyEco: selectConfigCopyEco(),
    configCopyNotif: selectConfigCopyNotif(),
    configCopyUnauth: selectConfigCopyUnauth(),
    configCopyLpm: selectConfigCopyLpm(),
    configCopyAccel: selectConfigCopyAccel(),
    targetVehicules: selectCopyTargetVehicles(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ copyConfiguration }, dispatch);

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FinishCopyComponent));
