import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ApReservationStepIcon, ApStepperSeperator } from '../../components/Icons';
import VehiculeListConfig from '../../components/VehiculeListConfig';
import NotificationConfig from '../../components/NotificationConfig';
import EconomieConfigComponent from '../../components/EconomieConfigComponent';
import UnauthorizedMoveConfigBox from '../../components/UnauthorizedMoveConfigBox';
import LowPowerModeSettings from '../../components/LowPowerModeSettings';
import AccelerometreSettings from '../../components/AccelerometreSettings';
import FinishCopyComponent from '../../components/FinishCopyComponent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { Checkbox, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './styles.scss';
import {
    getConfigByEquipementId,
    getEmailNotifConfigByEquipementId,
    copyConfiguration,
} from 'services/Config/thunks';
import {
    selectConfigCopyNotif,
    selectConfigCopyEco,
    selectConfigCopyUnauth,
    selectConfigCopyLpm,
    selectConfigCopyAccel,
    selectCopyTargetVehicles,
    selectVehicleToCopy,
    selectVehiculeRefs,
    selectConfigCopyError,
} from '../../services/Config/selectors';
import colors from 'styles/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function Configuration(props) {
    const getEquipmentConfig = (equipmentId) => {
        props.getConfigByEquipementId(equipmentId);
        props.getEmailNotifConfigByEquipementId(equipmentId);
    };
    const resetVehiculeSelection = () => {
        Object.keys(props.checkboxRefs.toJS().current).forEach((key) => {
            props.checkboxRefs.toJS().current[key].state.checked = false;
        });
    };
    const steps = [
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Config',
        }),
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Target',
        }),
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Finish',
        }),
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [allSelected, setAllSelected] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const copyConfigNotif = (placement, status) => {
        console.log(status);
        if (status == 204) {
            api.success({
                message: `Success`,
                description: `Success`,
                placement,
            });
        } else {
            api.warning({
                message: `!Oops`,
                description: `Error copying configuration`,
                placement,
            });
        }
    };
    const onClickCopy = () => {
        setActiveStep(1);
    };

    return (
        <Grid
            container
            sx={{
                mt: 5,
                mb: 5,
                border: `1px solid ${colors.green61}`,
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                padding: '20px',
                height: '75%',
                margin: '32px',
                width: '91%',
                overflowY: 'scroll',
            }}
        >
            <Grid item xs={12}>
                <h5 style={{ textAlign: 'left' }}>Configuration</h5>
            </Grid>
            {contextHolder}
            {activeStep > 0 && (
                <Grid
                    item
                    xs={2}
                    style={{
                        justifyContent: 'start',
                        display: 'flex',
                        paddingLeft: '30px',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            height: 'fit-content',
                            textTransform: 'none',
                            backgroundColor: '#4F9C20',
                            color: 'white',
                            borderColor: '#4F9C20',
                            '&:hover': {
                                backgroundColor: '#3E7C16',
                                borderColor: '#3E7C16',
                            },
                            margin: '0.5em',
                        }}
                        onClick={() => {
                            setActiveStep(activeStep - 1);
                            if (activeStep === 0) {
                                resetVehiculeSelection();
                            }
                        }}
                    >
                        <span>Back</span>
                    </Button>
                </Grid>
            )}
            {activeStep > 0 && (
                <Grid item xs={8}>
                    <Stepper
                        activeStep={activeStep}
                        sx={{ justifyContent: 'center', width: '115%' }}
                        connector={<ApStepperSeperator />}
                    >
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconComponent={() =>
                                            ApReservationStepIcon(index, activeStep)
                                        }
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
            )}
            <Grid
                item
                xs={2}
                mb={5}
                style={{ justifyContent: 'end', display: 'flex', paddingRight: '30px' }}
            >
                {activeStep === steps.length - 2 ? (
                    <Button
                        variant="outlined"
                        sx={{
                            height: 'fit-content',
                            textTransform: 'none',
                            backgroundColor: '#4F9C20',
                            color: 'white',
                            borderColor: '#4F9C20',
                            '&:hover': {
                                backgroundColor: '#3E7C16',
                                borderColor: '#3E7C16',
                            },
                        }}
                        onClick={() => {
                            setActiveStep(2);
                        }}
                    >
                        <span>Next</span>
                    </Button>
                ) : activeStep === steps.length - 1 ? (
                    <React.Fragment>
                        <Button
                            variant="outlined"
                            sx={{
                                height: 'fit-content',
                                textTransform: 'none',
                                backgroundColor: '#4F9C20',
                                color: 'white',
                                borderColor: '#4F9C20',
                                '&:hover': {
                                    backgroundColor: '#3E7C16',
                                    borderColor: '#3E7C16',
                                },
                            }}
                            onClick={() => {
                                props
                                    .copyConfiguration(
                                        props.vehicleToCopy.vehicleId,
                                        props.copyTargetVehicles.toJS(),
                                        props.configCopyEco,
                                        props.configCopyNotif,
                                        props.configCopyUnauth,
                                        props.configCopyLpm,
                                        props.configCopyAccel
                                    )
                                    .then((response) => {
                                        copyConfigNotif('top', response.status);
                                    })
                                    .catch((error) => {
                                        console.error('Error copying configuration', error);
                                    });
                            }}
                        >
                            <span>Finish</span>
                        </Button>
                    </React.Fragment>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            {activeStep === steps.length - 1 ? (
                <Grid container className="finishContainer">
                    <FinishCopyComponent />
                </Grid>
            ) : (
                <Grid
                    container
                    style={{
                        justifyContent: 'center'}}
                >
                    <Grid item xs={3}>
                        <VehiculeListConfig
                            step={activeStep}
                            onClickConfig={getEquipmentConfig}
                            onClickCopy={onClickCopy}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container>
                            <Grid
                                xs={12}
                                className="vehiculeItem"
                                style={{
                                    fontSize: '14px',
                                    fontFamily: 'inter',
                                    paddingBottom: '20px',
                                    fontWeight: '900',
                                    color: '#101828',
                                    paddingLeft: '20px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                {activeStep === 1 && (
                                    <div>
                                        <Checkbox
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setAllSelected(true);
                                                } else {
                                                    setAllSelected(false);
                                                }
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>Select All</span>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <EconomieConfigComponent checked={allSelected} step={activeStep} />
                                <LowPowerModeSettings checked={allSelected} step={activeStep} />
                            </Grid>

                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <AccelerometreSettings checked={allSelected} step={activeStep} />
                            </Grid>
                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <UnauthorizedMoveConfigBox
                                    checked={allSelected}
                                    step={activeStep}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ margin: '0.5em', maxWidth: '48%' }}>
                                <NotificationConfig checked={allSelected} step={activeStep} />
                            </Grid>
                            {activeStep === 0 && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        maxWidth: '97.3%',
                                        margin: '0.5em',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        sx={{
                                            height: 'fit-content',
                                            textTransform: 'none',
                                            backgroundColor: '#4F9C20',
                                            color: 'white',
                                            borderColor: '#4F9C20',
                                            '&:hover': {
                                                backgroundColor: '#3E7C16',
                                                borderColor: '#3E7C16',
                                            },
                                        }}
                                    >
                                        Sauvegarder
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
const mapStateToProps: Object = createStructuredSelector({
    configCopyEco: selectConfigCopyEco(),
    configCopyNotif: selectConfigCopyNotif(),
    configCopyUnauth: selectConfigCopyUnauth(),
    configCopyLpm: selectConfigCopyLpm(),
    configCopyAccel: selectConfigCopyAccel(),
    copyTargetVehicles: selectCopyTargetVehicles(),
    vehicleToCopy: selectVehicleToCopy(),
    checkboxRefs: selectVehiculeRefs(),
    copyConfigError: selectConfigCopyError(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        { getConfigByEquipementId, getEmailNotifConfigByEquipementId, copyConfiguration },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Configuration))
);
