// @flow

export const GET_CONFIG_BY_EQUIPEMENT_SUCCESS =
    'app/services/Config/GET_CONFIG_BY_EQUIPEMENT_SUCCESS';
export const GET_CONFIG_ERROR = 'app/services/Config/GET_CONFIG_ERROR';
export const GET_SETTINGS_BY_EQUIPEMENT_SUCCESS =
    'app/services/Config/GET_SETTINGS_BY_EQUIPEMENT_SUCCESS';
export const GET_MODULE_BY_EQUIPEMENT_SUCCESS =
    'app/services/Config/GET_MODULE_BY_EQUIPEMENT_SUCCESS';
export const GET_MODULE_ERROR = 'app/services/Config/GET_MODULE_ERROR';
export const LOADING_CONFIG = 'app/services/Config/LOADING_CONFIG';
export const LOADING_EMAILS = 'app/services/Config/LOADING_EMAILS';
export const GET_EMAIL_NOTIF_CONFIG_SUCCESS = 'app/services/Config/GET_EMAIL_NOTIF_CONFIG_SUCCESS';
export const GET_EMAILs_BRANCH_SUCCESS = 'app/services/Config/GET_EMAILs_BRANCH_SUCCESS';
export const GET_UNAUTHORIZED_MOVE_CONFIG_SUCCESS =
    'app/services/Config/GET_UNAUTHORIZED_MOVE_CONFIG_SUCCESS';
export const DISMISS_CONFIG_ERROR = 'app/services/Config/DISMISS_CONFIG_ERROR';
export const DISMISS_CONFIG_LOADING = 'app/services/Config/DISMISS_CONFIG_LOADING';
export const GET_ENGINE_CONFIG_GLOBAL_ACTIVATION =
    'app/services/Config/GET_ENGINE_CONFIG_GLOBAL_ACTIVATION';
export const UPDATED_ENGINE_CONFIG_GLOBAL_ACTIVATION =
    'app/services/Config/UPDATED_ENGINE_CONFIG_GLOBAL_ACTIVATION';
export const GET_ENGINE_CONFIG_CONDITIONS = 'app/services/Config/GET_ENGINE_CONFIG_CONDITIONS';
export const SET_ENGINE_CONDITION = 'app/services/Config/SET_ENGINE_CONDITION';
export const SET_NEW_CONDITION = 'app/services/Config/SET_NEW_CONDITION';
export const RESET_GLOBAL_ACTIVATION = 'app/services/Config/RESET_GLOBAL_ACTIVATION';
export const GET_ENGINE_CONFIG_DELAYS = 'app/services/Config/GET_ENGINE_CONFIG_DELAYS';
export const SET_VEHICLE_TO_COPY = 'app/services/Config/SET_VEHICLE_TO_COPY';
export const SET_LPM_TO_COPY = 'app/services/Config/SET_LPM_TO_COPY';
export const SET_ACCELEROMETRE_TO_COPY = 'app/services/Config/SET_ACCELEROMETRE_TO_COPY';
export const SET_ECONOMY_TO_COPY = 'app/services/Config/SET_ECONOMY_TO_COPY';
export const SET_UNAUTHORIZED_MOVE_TO_COPY = 'app/services/Config/SET_UNAUTHORIZED_MOVE_TO_COPY';
export const SET_NOTIFICATIONS_TO_COPY = 'app/services/Config/SET_NOTIFICATIONS_TO_COPY';
export const ADD_TARGET_VEHICLE = 'app/services/Config/ADD_TARGET_VEHICLE';
export const REMOVE_TARGET_VEHICLE = 'app/services/Config/REMOVE_TARGET_VEHICLE';
export const COPY_CONFIGURATION_SUCCESS = 'app/services/Config/COPY_CONFIGURATION_SUCCESS';
export const receivedGetConfigByEquipementIdSuccess = (ConfigRecords): GenericActionType => ({
    type: GET_CONFIG_BY_EQUIPEMENT_SUCCESS,
    ConfigRecords,
});

export const startLoading = (): GenericActionType => ({
    type: LOADING_CONFIG,
});

export const dismissConfigLoading = (): GenericActionType => ({
    type: DISMISS_CONFIG_LOADING,
});

export const startLoadingEmails = (): GenericActionType => ({
    type: LOADING_EMAILS,
});

export const receivedConfigError = (): GenericActionType => ({
    type: GET_CONFIG_ERROR,
});

export const receivedGetSettingsByEquipementIdSuccess = (SettingsRecords): GenericActionType => ({
    type: GET_SETTINGS_BY_EQUIPEMENT_SUCCESS,
    SettingsRecords,
});

export const receivedGetModuleByEquipementIdSuccess = (ModuleRecords): GenericActionType => ({
    type: GET_MODULE_BY_EQUIPEMENT_SUCCESS,
    ModuleRecords,
});

export const receivedGetEmailNotifConfigByEquipementId = (
    EmailNotifConfigRecords
): GenericActionType => ({
    type: GET_EMAIL_NOTIF_CONFIG_SUCCESS,
    EmailNotifConfigRecords,
});

export const receivedUnauthorizedMoveConfigByEquipementId = (
    UnauthorizedMoveRecords
): GenericActionType => ({
    type: GET_UNAUTHORIZED_MOVE_CONFIG_SUCCESS,
    UnauthorizedMoveRecords,
});

export const receivedGetEmailsByBranchId = (Emails): GenericActionType => ({
    type: GET_EMAILs_BRANCH_SUCCESS,
    Emails,
});

export const receivedModuleError = (): GenericActionType => ({
    type: GET_MODULE_ERROR,
});

export const receivedDissmissedConfigError = (): GenericActionType => ({
    type: DISMISS_CONFIG_ERROR,
});
GET_ENGINE_CONFIG_GLOBAL_ACTIVATION;

export const receivedEngineConfigGlobalActivation = (globalActivation): GenericActionType => ({
    type: GET_ENGINE_CONFIG_GLOBAL_ACTIVATION,
    globalActivation,
});
export const updatedEngineConfigGlobalActivation = (): GenericActionType => ({
    type: UPDATED_ENGINE_CONFIG_GLOBAL_ACTIVATION,
});
export const receivedEngineConfigConditions = (engineConditions): GenericActionType => ({
    type: GET_ENGINE_CONFIG_CONDITIONS,
    engineConditions,
});
export const putConditionInBox = (conditions): GenericActionType => ({
    type: SET_ENGINE_CONDITION,
    conditions,
});
export const putNewConditionInBox = (): GenericActionType => ({
    type: SET_NEW_CONDITION,
});
export const resetGlobalActivationAction = (): GenericActionType => ({
    type: RESET_GLOBAL_ACTIVATION,
});

export const receivedEngineConfigDelays = (engineConditionsDelays): GenericActionType => ({
    type: GET_ENGINE_CONFIG_DELAYS,
    engineConditionsDelays,
});
export const setVehicletoCopy = (vehicle): GenericActionType => ({
    type: SET_VEHICLE_TO_COPY,
    vehicle,
});
export const setLpmtoCopy = (checked:Boolean) : GenericActionType => ({
    type: SET_LPM_TO_COPY,
    checked
});
export const setAccelerometretoCopy = (checked:Boolean) : GenericActionType => ({
    type: SET_ACCELEROMETRE_TO_COPY,
    checked
});
export const setEconomytoCopy = (checked:Boolean) : GenericActionType => ({
    type: SET_ECONOMY_TO_COPY,
    checked
});
export const setUnauthorizedMovetoCopy = (checked:Boolean) : GenericActionType => ({
    type: SET_UNAUTHORIZED_MOVE_TO_COPY,
    checked
});
export const setNotificationstoCopy = (checked:Boolean) : GenericActionType => ({
    type: SET_NOTIFICATIONS_TO_COPY,
    checked
});
export const putTargetVehicle = (vehicle): GenericActionType => ({ 
    type: ADD_TARGET_VEHICLE,
    vehicle
});
export const deleteTargetVehicle = (vehicleId:Number): GenericActionType => ({
    type: REMOVE_TARGET_VEHICLE,
    vehicleId
});
export const copyConfigurationSuccess = (): GenericActionType => ({
    type: COPY_CONFIGURATION_SUCCESS,
});