import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {
    ApReservationStepIcon,
    ApCheckMarkIcon,
    ApCloseIcon,
    ApStepperSeperator,
    CopyIcon,
} from '../../components/Icons';
import VehiculeListConfig from '../../components/VehiculeListConfig';
import NotificationConfig from '../../components/NotificationConfig';
import EconomieConfigComponent from '../../components/EconomieConfigComponent';
import UnauthorizedMoveConfigBox from '../../components/UnauthorizedMoveConfigBox';
import LowPowerModeSettings from '../../components/LowPowerModeSettings';
import AccelerometreSettings from '../../components/AccelerometreSettings';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import './styles.scss';
import {
    getConfigByEquipementId,
    getEmailNotifConfigByEquipementId,
    getModuleByEquipementId,
    dismissConfigError,
} from 'services/Config/thunks';

function Configuration(props) {
    const getEquipmentConfig = (equipmentId) => {
        props.getConfigByEquipementId(equipmentId);
        props.getEmailNotifConfigByEquipementId(equipmentId);
    };
    const steps = [
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Config',
        }),
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Target',
        }),
        props.intl.formatMessage({
            id: 'views.Configuration.Stepper.Finish',
        }),
    ];
    const [activeStep, setActiveStep] = useState(0);
    return (
        <Grid container sx={{ mt: 5, height: 'fit-content' }}>
            <Grid item xs={9}>
                <Stepper
                    activeStep={activeStep}
                    sx={{ justifyContent: 'center', width: '115%' }}
                    connector={<ApStepperSeperator />}
                >
                    {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel
                                    StepIconComponent={() =>
                                        ApReservationStepIcon(index, activeStep)
                                    }
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item xs={2} mb={5} style={{ justifyContent: 'end', display:'flex' }}>
                <Button
                    variant="outlined"
                    startIcon={<CopyIcon />}
                    sx={{
                        height: 'fit-content',
                        textTransform: 'none',
                        backgroundColor: '#4F9C20',
                        color: 'white',
                        borderColor: '#4F9C20',
                        '&:hover': {
                            backgroundColor: '#3E7C16',
                            borderColor: '#3E7C16',
                        },
                    }}
                >
                    <span>Copy Configuration</span>
                </Button>
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <VehiculeListConfig onClickConfig={getEquipmentConfig} />
                </Grid>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item xs={6} style={{margin: '0.5em',maxWidth:'48%'}} >
                            <EconomieConfigComponent />
                        </Grid>

                        <Grid item xs={6} style={{margin: '0.5em',maxWidth:'48%'}}>
                            <NotificationConfig />
                        </Grid>

                        <Grid xs={6} style={{margin: '0.5em',maxWidth:'48%'}}>
                            <UnauthorizedMoveConfigBox />
                        </Grid>
                        <Grid item xs={6} style={{margin: '0.5em',maxWidth:'48%'}}>
                            <Grid container>
                                <Grid xs={12} style={{    marginBottom: '1em'}}>
                                    <LowPowerModeSettings />
                                </Grid>

                                <Grid xs={12}>
                                    <AccelerometreSettings />'
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
const mapStateToProps: Object = createStructuredSelector({});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators({ getConfigByEquipementId, getEmailNotifConfigByEquipementId }, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Configuration))
);
