// @flow

import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InputGroup, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { Switch } from 'antd';
import './styles.scss';
// Selectors
import { selectConfigRecords } from 'services/Config/selectors';

function LowPowerModeSettings(props) {
    const [wakeUpIntervalMinutes, setWakeUpIntervalMinutes] = useState(15);

    const [lpmCheck, setLpmCheck] = useState(false);

    useEffect(
        () => {
            if (!props.configRecords.get('lowPowerMode')) return;
            if (!props.configRecords.get('lowPowerMode').get('isEnabled')) return;
            if (!props.configRecords.get('lowPowerMode').get('wakeUpIntervalMinutes')) return;
            setLpmCheck(props.configRecords.get('lowPowerMode').get('isEnabled'));
            setWakeUpIntervalMinutes(
                props.configRecords.get('lowPowerMode').get('wakeUpIntervalMinutes')
            );
        },
        [props.configRecords]
    );

    const handleChange = async (check) => {
        setLpmCheck(check);
        await props.handleChange(getChangedItems());
    };

    const getChangedItems = () => {
        return [lpmCheck, wakeUpIntervalMinutes];
    };
    return (
        <Grid
            container 
            sx={{
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '20px',
                height: 'fit-content',
            }}
        >
            <span className="economieHeader">
                <FormattedMessage id={'components.Modals.Form.Config.LPM'} />
            </span>
            <Grid item xs={12} sx={{ mb: '15px' }}>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#B6C2E2' }} />
            </Grid>
            <Grid item container sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                 <Grid item xs={1}>
                    <Switch onChange={handleChange} checked={lpmCheck} />
                </Grid>
                <Grid item xs={4}>
                    <FormattedMessage id={'components.Modals.Form.Config.LPM.freq'} />
                </Grid>
               
                {lpmCheck ? (
                    <Grid item xs={6}>
                        <InputGroup>
                            <Form.Control
                                size="sm"
                                type="number"
                                onChange={async (e) => {
                                    const value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 5);
                                    setWakeUpIntervalMinutes(value);
                                    await props.handleChange(getChangedItems());
                                }}
                                placeholder=""
                                value={wakeUpIntervalMinutes}
                                className='LpmInput'
                            />
                            <InputGroup.Prepend style={{ height: 31 }}>
                                <InputGroup.Text id="inputGroupPrepend">min</InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
}

// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({
    configRecords: selectConfigRecords(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(LowPowerModeSettings));
