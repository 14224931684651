// @flow

import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import CustomSlider from 'components/CustomSlider';
import { ChevronUp, ChevronDown } from '../../components/Icons';
import ConfigCustomCheckbox from 'components/ConfigCustomCheckbox';
import { setAccelerometreCopy } from 'services/Config/thunks';
// Selectors
import { selectConfigRecords, selectModuleRecords } from 'services/Config/selectors';

type Props = {
    configRecords: *,
    moduleRecords: *,
    handleChange: *,
};

const AccelerometreSettings = (props: Props) => {
    const [detailed, setDetailed] = useState(true);
    useEffect(
        () => {
            if (props.step === 1) {
                setDetailed(false);
            }
        },
        [props.step]
    );
    useEffect(
        () => {
            if (!props.configRecords.get('accelerationDetection')) return;
            if (!props.configRecords.get('accelerationDetection').get('calibrationState')) return;
            if (!props.configRecords.get('accelerationDetection').get('calibrationStep')) return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('gForceAccelerationDecelerationThreshold')
            )
                return;
            if (!props.configRecords.get('accelerationDetection').get('impactThreshold')) return;
            if (!props.configRecords.get('accelerationDetection').get('sidesAccelerationThreshold'))
                return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('forwardAccelerationThreshold')
            )
                return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('reverseAccelerationThreshold')
            )
                return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('forwardDecelerationThreshold')
            )
                return;
            if (
                !props.configRecords
                    .get('accelerationDetection')
                    .get('reverseDecelerationThreshold')
            )
                return;
            setCalibrationState(
                props.configRecords.get('accelerationDetection').get('calibrationState')
            );
            setCalibrationStep(
                props.configRecords.get('accelerationDetection').get('calibrationStep')
            );
            setGForceAccelerationDecelerationThreshold(
                props.configRecords
                    .get('accelerationDetection')
                    .get('gForceAccelerationDecelerationThreshold')
                    ? parseFloat(
                          props.configRecords
                              .get('accelerationDetection')
                              .get('gForceAccelerationDecelerationThreshold')
                      )
                    : 0
            );
            setImpact(
                props.configRecords.get('accelerationDetection').get('impactThreshold')
                    ? parseFloat(
                          props.configRecords.get('accelerationDetection').get('impactThreshold')
                      )
                    : 0
            );
            setSeriesBar([
                {
                    name: props.intl.formatMessage({
                        id: 'components.Modals.Form.Config.Accelerometre.Accel',
                    }),
                    data: [
                        props.configRecords
                            .get('accelerationDetection')
                            .get('sidesAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('sidesAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('forwardAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('forwardAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('sidesAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('sidesAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('reverseAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('reverseAccelerationThreshold')
                              )
                            : 0,
                    ],
                },
                {
                    name: props.intl.formatMessage({
                        id: 'components.Modals.Form.Config.Accelerometre.Decel',
                    }),
                    data: [
                        props.configRecords
                            .get('accelerationDetection')
                            .get('sidesAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('sidesAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('forwardDecelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('forwardDecelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('sidesAccelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('sidesAccelerationThreshold')
                              )
                            : 0,
                        props.configRecords
                            .get('accelerationDetection')
                            .get('reverseDecelerationThreshold')
                            ? parseFloat(
                                  props.configRecords
                                      .get('accelerationDetection')
                                      .get('reverseDecelerationThreshold')
                              )
                            : 0,
                    ],
                },
            ]);
        },
        [props.configRecords]
    );
    // const accelerationDetection = props.configRecords.get('accelerationDetection');

    const [calibrationState, setCalibrationState] = useState('');
    const [calibrationStep, setCalibrationStep] = useState('');
    const [minorVersion, setMinorVersion] = useState(
        props.moduleRecords.getIn(['version', 'minor'])
    );
    const [buildVersion, setBuildVersion] = useState(
        props.moduleRecords.getIn(['version', 'build'])
    );
    const [
        gForceAccelerationDecelerationThreshold,
        setGForceAccelerationDecelerationThreshold,
    ] = useState(0);
    const [impact, setImpact] = useState(0);
    const [seriesBar, setSeriesBar] = useState([
        {
            name: props.intl.formatMessage({
                id: 'components.Modals.Form.Config.Accelerometre.Accel',
            }),
            data: [0, 0, 0, 0],
        },
        {
            name: props.intl.formatMessage({
                id: 'components.Modals.Form.Config.Accelerometre.Decel',
            }),
            data: [0, 0, 0, 0],
        },
    ]);

    // const getChangedItems = () => {
    //     return [gForceAccelerationDecelerationThreshold, impact, seriesBar];
    // };

    const isAccelCalibration = (minor, build) => {
        if (minor == 205 && build < 20) {
            return false;
        }
        if (minor < 205) {
            return false;
        }
        return true;
    };

    const handleSliderUpdate = async (index, value, seriesIndex) => {
        const newBarSeries = seriesBar.map((s, i) => {
            if (i === seriesIndex) {
                const newData = [...s.data];
                newData[index] = Number(value).toFixed(2);
                return { ...s, data: newData };
            }
            return s;
        });
        setSeriesBar(newBarSeries);
    };

    const handleImpactUpdate = async (value) => {
        setImpact(Number(value).toFixed(2));
    };

    const handleGForceUpdate = async (value) => {
        setGForceAccelerationDecelerationThreshold(Number(value).toFixed(2));
    };
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            props.setAccelerometreCopy(true);
        } else {
            props.setAccelerometreCopy(false);
        }
    };
    const isAccelCalibrated = isAccelCalibration(minorVersion, buildVersion);

    return (
        <Grid
            container
            sx={{
                border: 'solid 1px #B6C2E2',
                alignContent: 'flex-start',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2)',
                padding: '30px',
                height: detailed ? '34vh' : 'fit-content',
                overflowY: 'scroll',
            }}
        >
            <Grid xs={8}>
                <ConfigCustomCheckbox onChange={(e) =>handleCheckboxChange(e)} />
                <span className="economieHeader">
                    <FormattedMessage id={'components.Modals.Form.Config.Accelerometre'} />
                </span>
            </Grid>
            <Grid xs={4} sx={{ display: 'flex', justifyContent: 'end', padding: '2px 10px 0 0' }}>
                {detailed ? (
                    <div onClick={() => setDetailed(false)}>
                        <ChevronUp />
                    </div>
                ) : (
                    <div onClick={() => setDetailed(true)}>
                        <ChevronDown />
                    </div>
                )}
            </Grid>
            <Grid item xs={12} sx={{ mb: '15px' }}>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#B6C2E2' }} />
            </Grid>

            {detailed ? (
                <Grid item xs={12} container>
                    {isAccelCalibrated ? (
                        <Grid item xs={12}>
                            <Grid item xs={12} style={{ paddingTop: 10, height: 50 }}>
                                <FormattedMessage
                                    id={'components.Modals.Form.Config.Accelerometre.avant'}
                                />
                            </Grid>
                            <Grid item xs={12} container sx={{ paddingLeft: '30px', height: 50 }}>
                                <Grid item xs={4}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.Accel'}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        domain={[0, 4]}
                                        values={[seriesBar[0].data[1]]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            seriesBar.map((s, index) => {
                                                if (index == 0) {
                                                    newBarSeries.push({
                                                        data: [
                                                            seriesBar[0].data[0],
                                                            Number(e[0]).toFixed(2),
                                                            seriesBar[0].data[2],
                                                            seriesBar[0].data[3],
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container sx={{ paddingLeft: '30px', height: 50 }}>
                                <Grid item xs={4} style={{ height: 50 }}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.Decel'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        values={[seriesBar[1].data[1]]}
                                        domain={[0, 4]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            seriesBar.map((s, index) => {
                                                if (index == 1) {
                                                    newBarSeries.push({
                                                        data: [
                                                            seriesBar[0].data[0],
                                                            Number(e[0]).toFixed(2),
                                                            seriesBar[0].data[2],
                                                            seriesBar[1].data[3],
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ height: 50 }}>
                                <FormattedMessage
                                    id={'components.Modals.Form.Config.Accelerometre.arriere'}
                                />
                            </Grid>
                            <Grid item xs={12} container sx={{ paddingLeft: '30px', height: 50 }}>
                                <Grid item xs={4} style={{ height: 50 }}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.Accel'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        values={[seriesBar[0].data[3]]}
                                        domain={[0, 4]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            seriesBar.map((s, index) => {
                                                if (index == 0) {
                                                    newBarSeries.push({
                                                        data: [
                                                            seriesBar[0].data[0],
                                                            seriesBar[0].data[1],
                                                            seriesBar[0].data[2],
                                                            Number(e[0]).toFixed(2),
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container sx={{ paddingLeft: '30px', height: 50 }}>
                                <Grid item xs={4} style={{ height: 50 }}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.Decel'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        values={[seriesBar[1].data[3]]}
                                        domain={[0, 4]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            seriesBar.map((s, index) => {
                                                if (index == 1) {
                                                    newBarSeries.push({
                                                        data: [
                                                            seriesBar[0].data[0],
                                                            seriesBar[1].data[1],
                                                            seriesBar[0].data[2],
                                                            Number(e[0]).toFixed(2),
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container sx={{ height: 50 }}>
                                <Grid item xs={4} style={{ height: 50 }}>
                                    <FormattedMessage
                                        id={'components.Modals.Form.Config.Accelerometre.laterale'}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{ height: 50 }}>
                                    <CustomSlider
                                        mode={1}
                                        step={0.05}
                                        values={[seriesBar[0].data[0]]}
                                        domain={[0, 4]}
                                        onUpdate={async (e) => {
                                            const newBarSeries = [];
                                            seriesBar.map((s, index) => {
                                                if (index == 0) {
                                                    newBarSeries.push({
                                                        data: [
                                                            Number(e[0]).toFixed(2),
                                                            seriesBar[0].data[1],
                                                            Number(e[0]).toFixed(2),
                                                            seriesBar[0].data[3],
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else if (index == 1) {
                                                    newBarSeries.push({
                                                        data: [
                                                            Number(e[0]).toFixed(2),
                                                            seriesBar[1].data[1],
                                                            Number(e[0]).toFixed(2),
                                                            seriesBar[1].data[3],
                                                        ],
                                                        name: s.name,
                                                    });
                                                } else {
                                                    newBarSeries.push({
                                                        data: s.data,
                                                        name: s.name,
                                                    });
                                                }
                                            });
                                            setSeriesBar(newBarSeries);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} container sx={{ height: 50 }}>
                            <Grid item xs={4} style={{ height: 50 }}>
                                <FormattedMessage
                                    id={'components.Modals.Form.Config.Accelerometre.AccelDecel'}
                                />
                            </Grid>
                            <Grid item xs={8} style={{ height: 50 }}>
                                <CustomSlider
                                    mode={1}
                                    step={0.05}
                                    values={[gForceAccelerationDecelerationThreshold]}
                                    domain={[0, 4]}
                                    onUpdate={async (e) => {
                                        await setGForceAccelerationDecelerationThreshold(
                                            e[0]
                                        ).toFixed(2);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} container sx={{ height: 50 }}>
                        <Grid item xs={4} style={{ height: 50 }}>
                            <FormattedMessage
                                id={'components.Modals.Form.Config.Accelerometre.Impact'}
                            />
                        </Grid>
                        <Grid item xs={8} style={{ height: 50 }}>
                            <CustomSlider mode={1} step={0.05} values={[impact]} domain={[0, 4]} />
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );
};

// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({
    configRecords: selectConfigRecords(),
    moduleRecords: selectModuleRecords(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setAccelerometreCopy }, dispatch);

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AccelerometreSettings));
